import * as yup from 'yup';

import {Network} from '../types';
import {UseTranslationResponse} from 'react-i18next';
import {parsePhoneNumber} from 'libphonenumber-js';

export type YupTypes = {
    email: string;
    password: string;
    passwordNew: string;
    passwordNewConfirm: string;
    passwordConfirm: string;
    displayName: string;
    firstName: string;
    lastName: string;
    phone: string;
    category: number;
    subject: string;
    message: string;
    address1: string;
    address2: string;
    addressComplement: string;
    postalCode: string;
    city: string;
    country: string;
    corporateName: string;
    siret: string;
    url: string;
    network: string;
    comment: string;
    emoji: string;
    cgv: boolean;
    major: boolean;
};

export const yupLabels = {
    email: 'field.email',
    password: 'field.password',
    passwordNew: 'field.password_new',
    passwordNewConfirm: 'field.password_confirm',
    passwordConfirm: 'field.password_confirm',
    displayName: 'field.display_name',
    firstName: 'field.first_name',
    lastName: 'field.last_name',
    phone: 'field.phone',
    category: 'field.category',
    subject: 'field.subject',
    message: 'field.message',
    address1: 'field.address1',
    address2: 'field.address2',
    postalCode: 'field.postal_code',
    city: 'field.city',
    country: 'field.country',
    corporateName: 'field.corporate_name',
    siret: 'field.siret',
    url: 'field.url',
    network: 'field.network',
    comment: 'field.comment',
    emoji: 'field.emoji',
    cgv: 'field.cgv',
    major: 'field.major',
};

async function testPhone(value: string) {
    // console.log('TEST PHONE');
    // console.log('value = ', value);
    if (value) {
        try {
            const phone = parsePhoneNumber(value, 'FR');
            const valid = phone.isValid();
            // console.log('phone = ', phone);
            // console.log('valid = ', valid);
            return valid;
        } catch (e) {
            // console.log('Test phone error = ', e);
            return false;
        }
    } else {
        return false;
    }
}

export function yupRules(i18n: UseTranslationResponse<'translation', undefined>) {
    return {
        email: yup.string().email().required().label(i18n.t(yupLabels.email)),
        password: yup.string().min(6).required().label(i18n.t(yupLabels.password)),
        passwordNew: yup.string().min(6).required().label(i18n.t(yupLabels.passwordNew)),
        passwordConfirm: yup
            .string()
            .oneOf([yup.ref('password'), null], i18n.t('error.same_password'))
            .min(6)
            .required()
            .label(i18n.t(yupLabels.passwordConfirm)),
        passwordNewConfirm: yup
            .string()
            .oneOf([yup.ref('passwordNew'), null], i18n.t('error.same_password'))
            .min(6)
            .required()
            .label(i18n.t(yupLabels.passwordNewConfirm)),
        category: yup.number().required().label(i18n.t(yupLabels.category)),
        subject: yup.string().min(1).max(100).required().label(i18n.t(yupLabels.subject)),
        message: yup.string().min(1).max(1000).required().label(i18n.t(yupLabels.message)),
        displayName: yup.string().required().max(100).label(i18n.t(yupLabels.displayName)),
        firstName: yup.string().required().max(100).label(i18n.t(yupLabels.firstName)),
        lastName: yup.string().required().max(100).label(i18n.t(yupLabels.lastName)),
        phone: yup
            .string()
            .required()
            .test('is-phone', i18n.t('error.invalid_phone'), (value) => testPhone(value))
            .label(i18n.t(yupLabels.phone)),
        address1: yup.string().required().max(200).label(i18n.t(yupLabels.address1)),
        address2: yup.string().max(200).label(i18n.t(yupLabels.address2)),
        postalCode: yup.string().required().max(10).label(i18n.t(yupLabels.postalCode)),
        city: yup.string().required().max(50).label(i18n.t(yupLabels.city)),
        country: yup.string().required().max(50).label(i18n.t(yupLabels.country)),
        corporateName: yup
            .string()
            .required()
            .min(2)
            .max(100)
            .label(i18n.t(yupLabels.corporateName)),
        siret: yup.string().required().length(14).label(i18n.t(yupLabels.siret)),
        url: yup.string().max(50).label(i18n.t(yupLabels.url)),
        network: yup
            .mixed<Network>()
            .oneOf(Object.values(Network))
            .required()
            .label(i18n.t(yupLabels.network)),
        comment: yup.string().required().min(5).max(500).label(i18n.t(yupLabels.comment)),
        emoji: yup.string().label(i18n.t(yupLabels.emoji)),
        cgv: yup
            .bool()
            .required()
            .oneOf([true], i18n.t('error.cgv'))
            .label(i18n.t(yupLabels.cgv)),
        major: yup
            .bool()
            .required()
            .oneOf([true], i18n.t('error.majority'))
            .label(i18n.t(yupLabels.major)),
    };
}

export default yup;
