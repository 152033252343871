import {
    Box,
    FormControl,
    FormControlLabel,
    FormControlLabelProps,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
    TextField,
    TextFieldProps,
    TextareaAutosize,
    TextareaAutosizeProps,
    Typography,
    useTheme,
} from '@mui/material';
import {
    EUROPE_DELIVERY_COUNTRY_CODE,
    EeeCountryCodes,
    REST_OF_THE_WORLD_DELIVERY_ALPHA2,
    countriesWithin,
} from '../../data/countryNames';
import {Network, Theme} from '../../types';
import {QuestionMark, Visibility, VisibilityOff} from '@mui/icons-material';

import {Colors} from './colors';
import {Popup} from './modal';
import {SupportCategory} from '../../types/models/SupportCategory';
import {TabTitle} from './text';
import {Tip} from './tip';
import {capitalizeFirstLetter} from '../../utils/tool';
import dynamic from 'next/dynamic';
import {getCountryName} from '../../utils/country';
import {getLocaleLabels} from '../../utils/lang';
import {useResponsive} from '../../provider/MediaQueryProvider';
import {useState} from 'react';
import {useTranslation} from 'next-i18next';
import {yupLabels} from '../../utils/yup';

const MuiPhoneNumber = dynamic(() => import('material-ui-phone-number'));

const INPUT_MOBILE_SIZE = '250px';
const INPUT_TABLET_SIZE = '350px';
const INPUT_NORMAL_SIZE = '450px';

export default function Input(props: TextFieldProps) {
    const {isMobile, isTablet} = useResponsive();

    return (
        <TextField
            sx={{
                width: isMobile
                    ? INPUT_MOBILE_SIZE
                    : isTablet
                    ? INPUT_TABLET_SIZE
                    : INPUT_NORMAL_SIZE,
            }}
            margin="normal"
            {...props}
        />
    );
}

export function FirstNameInput(props: TextFieldProps) {
    const {t} = useTranslation('common');

    return (
        <Input
            variant="outlined"
            id="firstName"
            name="firstName"
            autoComplete="given-name"
            label={t(yupLabels.firstName)}
            {...props}
        />
    );
}

export function LastNameInput(props: TextFieldProps) {
    const {t} = useTranslation('common');

    return (
        <Input
            variant="outlined"
            id="lastName"
            name="lastName"
            autoComplete="family-name"
            label={t(yupLabels.lastName)}
            {...props}
        />
    );
}

export function DisplayNameInput(props: TextFieldProps) {
    const {t} = useTranslation('common');

    return (
        <Input
            variant="outlined"
            id="displayName"
            name="displayName"
            autoComplete="nickname"
            label={t(yupLabels.displayName)}
            {...props}
        />
    );
}

export function CorporateNameInput(props: TextFieldProps) {
    const {t} = useTranslation('common');

    return (
        <Input
            variant="outlined"
            id="corporateName"
            name="corporateName"
            autoComplete="organization"
            label={t(yupLabels.corporateName) + ' (' + 'pro' + ')'}
            {...props}
        />
    );
}

export function SiretInput(props: TextFieldProps) {
    const {t} = useTranslation('common');

    return (
        <Input
            variant="outlined"
            id="siret"
            name="siret"
            autoComplete="off"
            label={t(yupLabels.siret) + ' (' + 'pro' + ')'}
            {...props}
        />
    );
}

export function EmailInput(props: TextFieldProps & {tips: boolean}) {
    const {t} = useTranslation('common');

    const {tips, ...otherProps} = props;

    return (
        <Box display="flex" flexDirection="row">
            <Input
                variant="outlined"
                id="email"
                name="email"
                autoComplete="email"
                label={t(yupLabels.email)}
                {...otherProps}
            />
            {tips && (
                <Box
                    sx={{
                        alignSelf: 'center',
                        mt: '15px',
                    }}
                >
                    <Tip title={t('tips.login')} />
                </Box>
            )}
        </Box>
    );
}

export function PasswordInput(
    props: TextFieldProps & {autoComplete: 'password' | 'new-password'}
) {
    const {t} = useTranslation('common');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <Box display="flex" flexDirection="row">
            <Input
                variant="outlined"
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete={props.autoComplete}
                label={t(yupLabels.password)}
                {...props}
            />
            <IconButton
                aria-label="toggle password visibility"
                title={showPassword ? t('action.hide') : t('action.show')}
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
                sx={{
                    alignSelf: 'flex-start',
                    mt: '24px',
                }}
            >
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </Box>
    );
}

export function ConfirmPasswordInput(props: TextFieldProps) {
    const {t} = useTranslation('common');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <Box display="flex" flexDirection="row">
            <Input
                variant="outlined"
                id="passwordConfirm"
                name="passwordConfirm"
                type={showPassword ? 'text' : 'password'}
                autoComplete={'new-password'}
                label={t(yupLabels.passwordConfirm)}
                {...props}
            />
            <IconButton
                aria-label="toggle password visibility"
                title={showPassword ? t('action.hide') : t('action.show')}
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
                sx={{
                    alignSelf: 'flex-start',
                    mt: '24px',
                }}
            >
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </Box>
    );
}

export function NewPasswordInput(props: TextFieldProps) {
    const {t} = useTranslation('common');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <Box display="flex" flexDirection="row">
            <Input
                variant="outlined"
                id="passwordNew"
                name="passwordNew"
                type={showPassword ? 'text' : 'password'}
                autoComplete={'new-password'}
                label={t(yupLabels.passwordNew)}
                {...props}
            />
            <IconButton
                aria-label="toggle password visibility"
                title={showPassword ? t('action.hide') : t('action.show')}
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
                sx={{
                    alignSelf: 'center',
                    mt: '5px',
                }}
            >
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </Box>
    );
}

export function ConfirmNewPasswordInput(props: TextFieldProps) {
    const {t} = useTranslation('common');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <Box display="flex" flexDirection="row">
            <Input
                variant="outlined"
                id="passwordNewConfirm"
                name="passwordNewConfirm"
                type={showPassword ? 'text' : 'password'}
                autoComplete={'new-password'}
                label={t(yupLabels.passwordConfirm)}
                {...props}
            />
            <IconButton
                aria-label="toggle password visibility"
                title={showPassword ? t('action.hide') : t('action.show')}
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
                sx={{
                    alignSelf: 'flex-start',
                    mt: '24px',
                }}
            >
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </Box>
    );
}

type CategoryProps = {
    helper: string;
    categories: SupportCategory[];
    theme?: Theme | null | undefined;
};

export function CategorySelect(props: SelectProps & CategoryProps) {
    const {t, i18n} = useTranslation('common');
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const color = props.theme?.background_color ?? Colors.first;

    return (
        <FormControl
            margin="normal"
            sx={{
                '&:hover label': {
                    color: color,
                },
            }}
        >
            <InputLabel
                id="demo-simple-select-label"
                sx={{
                    '&.Mui-focused': {
                        color: color,
                    },
                }}
            >
                {t(yupLabels.category)}
            </InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t(yupLabels.category)}
                {...props}
                sx={{
                    backgroundColor: isDark ? Colors.dark : 'transparent',
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: color,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: color,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: color,
                    },
                    ...props.sx,
                }}
            >
                {props.categories.map((c) => (
                    <MenuItem value={c.order} key={c.order}>
                        {getLocaleLabels(c.labels, i18n.language).label}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{props.helper}</FormHelperText>
        </FormControl>
    );
}

export function SubjectInput(props: TextFieldProps) {
    const {t} = useTranslation('common');

    return (
        <Input
            variant="outlined"
            id="subject"
            name="subject"
            autoComplete="off"
            label={t(yupLabels.subject)}
            {...props}
        />
    );
}

export function MessageInput(props: TextFieldProps) {
    const {t} = useTranslation('common');

    return (
        <Input
            variant="outlined"
            id="message"
            name="message"
            autoComplete="off"
            label={t(yupLabels.message)}
            {...props}
        />
    );
}

export function UrlInput(props: TextFieldProps) {
    const {t} = useTranslation('common');

    return (
        <Input
            variant="outlined"
            id="url"
            name="url"
            autoComplete="off"
            label={t(yupLabels.url)}
            {...props}
        />
    );
}

type NetworkSelectProps = {
    helper: string;
};

export function NetworkSelect(props: SelectProps & NetworkSelectProps) {
    const {t, i18n} = useTranslation('common');

    const networks = Object.values(Network);

    return (
        <FormControl margin="normal">
            <InputLabel id="demo-simple-select-label">{t(yupLabels.network)}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t(yupLabels.network)}
                sx={{width: 300}}
                {...props}
            >
                {networks.map((n) => (
                    <MenuItem value={n} key={n}>
                        {capitalizeFirstLetter(n)}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{props.helper}</FormHelperText>
        </FormControl>
    );
}

export function PhoneInput(props: TextFieldProps & {countryCode: string}) {
    const {isMobile, isTablet} = useResponsive();
    const {t} = useTranslation('common');

    return (
        <>
            <MuiPhoneNumber
                variant="outlined"
                id="phone"
                name="phone"
                autoComplete="phone"
                label={t(yupLabels.phone)}
                defaultCountry={props.countryCode.toLocaleLowerCase()}
                value={props.value}
                onChange={props.onChange}
                error={props.error}
                helperText={props.helperText}
                sx={{
                    width: isMobile
                        ? INPUT_MOBILE_SIZE
                        : isTablet
                        ? INPUT_TABLET_SIZE
                        : INPUT_NORMAL_SIZE,
                }}
            />
        </>
    );
}

export function AddressInput(props: TextFieldProps) {
    const {t} = useTranslation('common');
    return (
        <Input
            variant="outlined"
            id="address1"
            name="address1"
            autoComplete="street-address"
            label={t(yupLabels.address1)}
            {...props}
        />
    );
}

export function AddressComplementInput(props: TextFieldProps) {
    const {t} = useTranslation('common');
    return (
        <Input
            variant="outlined"
            id="address2"
            name="address2"
            autoComplete="street-address"
            label={t(yupLabels.address2)}
            {...props}
        />
    );
}

export function CityInput(props: TextFieldProps) {
    const {t} = useTranslation('common');
    return (
        <Input
            variant="outlined"
            id="city"
            name="city"
            autoComplete="address-level2"
            label={t(yupLabels.city)}
            {...props}
        />
    );
}

export function PostalCodeInput(props: TextFieldProps) {
    const {t} = useTranslation('common');
    return (
        <Input
            variant="outlined"
            id="postalCode"
            name="postalCode"
            autoComplete="postal-code"
            label={t(yupLabels.postalCode)}
            {...props}
        />
    );
}

export function CountryInput(props: TextFieldProps) {
    const {t} = useTranslation('common');
    return (
        <Input
            variant="outlined"
            id="country"
            name="country"
            autoComplete="country-name"
            label={t(yupLabels.country)}
            {...props}
        />
    );
}

export function CommentInput(props: TextareaAutosizeProps) {
    const {isMobile, isTablet} = useResponsive();
    const {t} = useTranslation('common');
    return (
        <TextareaAutosize
            id="comment"
            name="comment"
            aria-label="minimum height"
            minRows={5}
            placeholder={t(yupLabels.comment)}
            style={{
                maxWidth: '100%',
                borderColor: Colors.somber,
                borderRadius: '5px',
                padding: '5px',
                outline: 'none',
            }}
            autoComplete="off"
            {...props}
        />
        // <Input
        //     variant="outlined"
        //     id="comment"
        //     name="comment"
        //     autoComplete="off"
        //     fullWidth
        //     label={t(yupLabels.comment)}
        //     {...props}
        // />
    );
}

type CityProps = {
    helper: string;
    cities: string[];
};

export function CitySelect(props: SelectProps & CityProps) {
    const {isMobile, isTablet} = useResponsive();
    const {t, i18n} = useTranslation('common');

    return (
        <FormControl margin="normal">
            <InputLabel id="demo-simple-select-label">{t(yupLabels.city)}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t(yupLabels.city)}
                displayEmpty
                sx={{
                    width: isMobile
                        ? INPUT_MOBILE_SIZE
                        : isTablet
                        ? INPUT_TABLET_SIZE
                        : INPUT_NORMAL_SIZE,
                }}
                {...props}
            >
                {props.cities.map((c) => (
                    <MenuItem value={c} key={c}>
                        {c}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{props.helper}</FormHelperText>
        </FormControl>
    );
}

type CountryAddressProps = {
    deliveryCountryCode: string;
    helper: string;
    hideDeliveryCountries: string[];
};

export function CountryAddressSelect(props: SelectProps & CountryAddressProps) {
    const {isMobile, isTablet} = useResponsive();
    const {t, i18n} = useTranslation('common');
    const countryCodes = countriesWithin(props.deliveryCountryCode);

    let hideDeliveryCountries = props.hideDeliveryCountries;
    if (hideDeliveryCountries.includes(EUROPE_DELIVERY_COUNTRY_CODE)) {
        hideDeliveryCountries = hideDeliveryCountries.filter(
            (cc) => cc !== EUROPE_DELIVERY_COUNTRY_CODE
        );
        hideDeliveryCountries = hideDeliveryCountries.concat(EeeCountryCodes);
    }

    return (
        <>
            <FormControl margin="normal">
                <InputLabel id="country-select-label">{t(yupLabels.country)}</InputLabel>
                <Select
                    labelId="country-select-label"
                    id="country-select"
                    label={t(yupLabels.country)}
                    displayEmpty
                    sx={{
                        width: isMobile
                            ? INPUT_MOBILE_SIZE
                            : isTablet
                            ? INPUT_TABLET_SIZE
                            : INPUT_NORMAL_SIZE,
                    }}
                    {...props}
                >
                    {countryCodes
                        .filter((cc2) => !props.hideDeliveryCountries.includes(cc2))
                        .map((cc2) => (
                            <MenuItem value={cc2} key={cc2}>
                                {getCountryName(cc2, i18n.language)}
                            </MenuItem>
                        ))}
                </Select>
                <FormHelperText>{props.helper}</FormHelperText>
            </FormControl>
            {props.deliveryCountryCode == EUROPE_DELIVERY_COUNTRY_CODE && (
                <InfoHiddenDeliveryCountries hideDeliveryCountries={hideDeliveryCountries} />
            )}
            {props.deliveryCountryCode == REST_OF_THE_WORLD_DELIVERY_ALPHA2 &&
                props.hideDeliveryCountries.includes(EUROPE_DELIVERY_COUNTRY_CODE) && (
                    <InfoHiddenDeliveryCountriesEurope
                        hideDeliveryCountries={hideDeliveryCountries}
                    />
                )}
        </>
    );
}

function InfoHiddenDeliveryCountries(props: {hideDeliveryCountries: string[]}) {
    const {t, i18n} = useTranslation('common');
    const [open, setOpen] = useState(false);
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    marginTop: 2,
                    alignItems: 'center',
                }}
            >
                <IconButton
                    aria-label={t('action.play')}
                    onClick={() => setOpen(true)}
                    sx={{width: 20, height: 20}}
                >
                    <QuestionMark sx={{fontSize: 12}} />
                </IconButton>
                <Typography
                    sx={{
                        maxWidth: 400,
                        fontSize: 'small',
                    }}
                >
                    {t('delivery.countries_not_listed')}
                </Typography>
            </Box>

            <Popup open={open} onClose={() => setOpen(false)}>
                <>
                    <TabTitle>{t('delivery.country_list')}</TabTitle>
                    <Typography>
                        {props.hideDeliveryCountries
                            .filter((cc2) => cc2 !== REST_OF_THE_WORLD_DELIVERY_ALPHA2)
                            .map((cc2) => getCountryName(cc2, i18n.language))
                            .join(', ')}
                    </Typography>
                </>
            </Popup>
        </>
    );
}

function InfoHiddenDeliveryCountriesEurope(props: {hideDeliveryCountries: string[]}) {
    const {t, i18n} = useTranslation('common');
    const [open, setOpen] = useState(false);
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    marginTop: 2,
                    alignItems: 'center',
                }}
            >
                <IconButton
                    aria-label={t('action.play')}
                    onClick={() => setOpen(true)}
                    sx={{width: 20, height: 20}}
                >
                    <QuestionMark sx={{fontSize: 12}} />
                </IconButton>
                <Typography
                    sx={{
                        maxWidth: 400,
                        fontSize: 'small',
                    }}
                >
                    {t('delivery.european_countries_not_listed')}
                </Typography>
            </Box>

            <Popup open={open} onClose={() => setOpen(false)}>
                <>
                    <TabTitle>{t('delivery.european_countries')}</TabTitle>
                    <Typography>
                        {props.hideDeliveryCountries
                            .map((cc2) => getCountryName(cc2, i18n.language))
                            .join(', ')}
                    </Typography>
                </>
            </Popup>
        </>
    );
}

export function CgvCheckBox(props: FormControlLabelProps & {helperText: string}) {
    const {isMobile, isTablet} = useResponsive();

    const {helperText, ...otherProps} = props;

    return (
        <>
            <FormControlLabel
                name="cgv"
                sx={{
                    maxWidth: isMobile ? 290 : isTablet ? 390 : 490,
                    marginTop: '10px',
                    alignSelf: 'flex-start',
                }}
                {...otherProps}
            />
            <FormHelperText error sx={{alignSelf: 'flex-start', marginLeft: '14px'}}>
                {helperText}
            </FormHelperText>
        </>
    );
}

export function MajorCheckBox(props: FormControlLabelProps & {helperText: string}) {
    const {isMobile, isTablet} = useResponsive();

    const {helperText, ...otherProps} = props;

    return (
        <>
            <FormControlLabel
                name="major"
                sx={{
                    maxWidth: isMobile ? 290 : isTablet ? 390 : 490,
                    marginTop: '10px',
                    alignSelf: 'flex-start',
                }}
                {...otherProps}
            />
            <FormHelperText error sx={{alignSelf: 'flex-start', marginLeft: '14px'}}>
                {helperText}
            </FormHelperText>
        </>
    );
}
