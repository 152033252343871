import {Box, ClickAwayListener, Typography} from '@mui/material';
import {useState} from 'react';
import {useResponsive} from '../../provider/MediaQueryProvider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Colors} from './colors';
import dynamic from 'next/dynamic';

const Tooltip = dynamic(() => import('@mui/material/Tooltip'));

type TipProps = {
    title: string;
};

export function Tip(props: TipProps) {
    const {isMobile, isTablet} = useResponsive();
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        // console.log('handleTooltipClose');
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        // console.log('handleTooltipOpen');
        setOpen(true);
    };

    if (isTablet || isMobile) {
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                    <Tooltip
                        arrow
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={props.title}
                        sx={{alignSelf: 'center', margin: '8px'}}
                    >
                        <InfoOutlinedIcon onClick={handleTooltipOpen} />
                    </Tooltip>
                </div>
            </ClickAwayListener>
        );
    } else {
        return (
            <Tooltip title={props.title} arrow sx={{alignSelf: 'center', margin: '8px'}}>
                <InfoOutlinedIcon />
            </Tooltip>
        );
    }
}
type FixedTipProps = {
    title: string;
};
export function FixedTip(props: FixedTipProps) {
    return (
        <Box
            sx={{
                backgroundColor: Colors.grey,
                padding: '5px',
            }}
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
        >
            <InfoOutlinedIcon sx={{color: 'white', alignSelf: 'center'}} />
            <Typography
                sx={{
                    color: 'white',
                    fontSize: '12px',
                    textAlign: 'center',
                    display: 'inline-block',
                    alignSelf: 'center',
                }}
            >
                {props.title}
            </Typography>
        </Box>
    );
}
