import {alpha} from '@mui/material';
import {countryNames} from '../data/countryNames';

const DEFAULT_COUNTRY_NAMES_LAN = 'FR';
const UNKNOWN_COUNTRY_NAME = 'NA';

export function getCountryName(alpha2: string, lang: string) {
    const countries = getCountryNames(lang.toUpperCase());
    return countries.get(alpha2.toUpperCase()) ?? UNKNOWN_COUNTRY_NAME;
}

export function getCountryNames(lang: string) {
    return countryNames.get(lang.toUpperCase()) ?? countryNames.get(DEFAULT_COUNTRY_NAMES_LAN);
}
